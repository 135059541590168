<template>
    <item-wrapper>
        <item-header :title="`Support Category: ${goodsType}`" />
        <main>
            <div class="main-wrapper">
                <div class="question-type">
                    <div v-for="(item, index) in supportData.detail"
                    :key="item.questionType"
                    @click="typeActive(index)"
                    :class="[{'active': index === detailIndex}, 'question-type-item']"
                    >
                        <img :src="item.img" alt="">
                        <span>{{ item.questionType }}</span>
                    </div>
                </div>
                <div class="question-describe-solution-wrapper">
                    <div class="question-describe-btn">
                            <div
                            v-for="(btnItem, index) in questionList"
                            :key='btnItem.questionDescribe'
                            @click="btnActive(index)"
                            :class="[{'active': index === btnIndex}, 'btn-item']"
                            >
                            {{btnItem.questionDescribe}}
                            </div>
                    </div>
                    <div class="question-solution">
                        <div class="solution-content">
                        <div class="solution-item"
                        v-for="(solutionItem, index) in questionSolution"
                        :key="index"
                        v-html="solutionItem"/>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </item-wrapper>
</template>

<script lang="ts">
import importComponents from '@/utils/import-components';
import {
  computed, defineComponent, reactive, ref,
} from 'vue';
import ItemHeader from '@/components/footerlink/ItemHeader.vue';
import ItemWrapper from '@/components/footerlink/ItemWrapper.vue';
import { useRoute } from 'vue-router';
import supportDetail from '@/views/footerlink/support/support-detail';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'SupportDetail',
  components: {
    ItemHeader,
    ItemWrapper,
    ...importComponents(
    ),
  },
  setup() {
    const route = useRoute();
    const { t } = useI18n();
    const { type } = route.params;
    const goodsType = type;
    const supportData = reactive({
      ...supportDetail(t).filter((item) => item.type === type)[0],
    });
    const detailIndex = ref(0);
    const btnIndex = ref(0);
    const questionList = computed(() => supportData.detail[detailIndex.value]
      .questionList);
    const questionSolution = computed(() => supportData.detail[detailIndex.value]
      .questionList[btnIndex.value].questionSolution);
    const typeActive = (index: number) => {
      btnIndex.value = 0;
      detailIndex.value = index;
    };
    const btnActive = (index: number) => {
      btnIndex.value = index;
    };

    return {
      goodsType,
      supportData,
      detailIndex,
      typeActive,
      btnActive,
      btnIndex,
      questionSolution,
      questionList,
      t,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixin.scss";
@import '@/assets/styles/variables.scss';

main {
    @include flex-center();
    margin-bottom: 100px;
    .main-wrapper {
        @include flex-center();
        flex-direction: column;
        width: 1280px;
        margin-right: -29px;
        .question-type {
            @include flex-center();
            .question-type-item {
                @include flex-center();
                margin: 0 14.5px;
                flex-direction: column;
                border-radius: 6px;
                border: 1px solid #969696;
                padding: 14px 11px;
                width: 180px;
                height: 105px;
                cursor: pointer;

                &.active {
                    box-shadow: 0px 3px 6px 0px rgba(37, 37, 37, 0.16);
                    border: 1px solid $theme-color;
                }

                &:hover {
                    border: 1px solid $theme-color;
                }

                img {
                    width: 40px;
                    height: 40px;
                    margin-bottom: 5px;
                }

                span {
                    font-size: 13px;
                    font-weight: 500;
                    color: $font-color;
                    line-height: 20px;
                    // white-space: nowrap;
                    text-align: center;
                }
            }
        }
        .question-describe-solution-wrapper{
            width: 100%;
            .question-describe-btn {
                @include flex-center();
                width: 1280px;
                padding-top: 91px;

                .btn-item {
                    @include flex-center();
                    width: 275px;
                    height: 71px;
                    border-radius: 2px;
                    border: 1px solid #969696;
                    font-size: 14px;
                    font-weight: 500;
                    color: $font-color;
                    line-height: 20px;
                    margin: 0px 54.5px;
                    cursor: pointer;
                    padding: 0 10px;
                    text-align: center;

                    &:hover {
                        border: 1px solid $theme-color;
                    }
                    &.active {
                        box-shadow: 0px 3px 6px 0px rgba(37, 37, 37, 0.16);
                        border: 1px solid $theme-color;
                }
                }
            }
            .question-solution {
                @include flex-center();
                width: 1280px;
                margin-top: 55px;
                .solution-content{
                    width: 425px;
                    .solution-item {
                        font-size: 14px;
                        font-weight: 500;
                        color: $font-color;
                        line-height: 20px;
                        padding: 10px 0;
                    }
                }
            }
        }
    }
}
</style>
