import type { Composer } from 'vue-i18n';

export default function supportDetail(t: Composer['t']) {
  return [
    {
      type: 'iPad',
      detail: [
        {
          questionType: t('supportDetailData.iPad.detail1.questionType'),
          img: '/images/support-detail/audio.png',
          questionList: [
            {
              questionDescribe: t('supportDetailData.iPad.detail1.questionDescribe'),
              questionSolution: [
                `<p>${t('supportDetailData.iPad.detail1.questionSolution.s1')}</p>`,
                `<p>${t('supportDetailData.iPad.detail1.questionSolution.s2')}</p>`,
                `<P>${t('supportDetailData.iPad.detail1.questionSolution.s3')}</p>`,
              ],
            },
          ],
        },
        {
          questionType: t('supportDetailData.iPad.detail2.questionType'),
          img: '/images/support-detail/battery.png',
          questionList: [
            {
              questionDescribe: t('supportDetailData.iPad.detail2.questionDescribe'),
              questionSolution: [
                `<p>${t('supportDetailData.iPad.detail2.questionSolution.s1')}</p>`,
              ],
            },
            {
              questionDescribe: 'Battery is Swollen',
              questionSolution: [
                `<P>${t('supportDetailData.iPad.detail2.questionSolution.s2')} <a href="/returns">${t('supportDetailData.iPad.detail2.questionSolution.s3')}</a>.</P>`,
              ],
            },
          ],
        },
        {
          questionType: t('supportDetailData.iPad.detail3.questionType'),
          img: '/images/support-detail/condition.png',
          questionList: [
            {
              questionDescribe: t('supportDetailData.iPad.detail3.questionDescribe.d1'),
              questionSolution: [
                `<P>${t('supportDetailData.iPad.detail3.questionSolution.s1')}</P>`,
                `<P>${t('supportDetailData.iPad.detail3.questionSolution.s2')}</P><P>${t('supportDetailData.iPad.detail3.questionSolution.s8')}</P>`,
                `<P>${t('supportDetailData.iPad.detail3.questionSolution.s3')}</P><P>${t('supportDetailData.iPad.detail3.questionSolution.s9')}</P>`,
                `<P>${t('supportDetailData.iPad.detail3.questionSolution.s4')}</P><P>${t('supportDetailData.iPad.detail3.questionSolution.s10')}</P>`,
              ],
            },
            {
              questionDescribe: t('supportDetailData.iPad.detail3.questionDescribe.d2'),
              questionSolution: [
                `<P>${t('supportDetailData.iPad.detail3.questionSolution.s5')}</P>`,

              ],
            },
            {
              questionDescribe: t('supportDetailData.iPad.detail3.questionDescribe.d3'),
              questionSolution: [
                `<P>${t('supportDetailData.iPad.detail3.questionSolution.s6')}</P>`,
              ],
            },
            {
              questionDescribe: t('supportDetailData.iPad.detail3.questionDescribe.d4'),
              questionSolution: [
                `<P>${t('supportDetailData.iPad.detail3.questionSolution.s7')}</P>`,
              ],
            },
          ],
        },
        {
          questionType: t('supportDetailData.iPad.detail4.questionType'),
          img: '/images/support-detail/connectivity.png',
          questionList: [
            {
              questionDescribe: t('supportDetailData.iPad.detail4.questionDescribe.d1'),
              questionSolution: [
                `<P>${t('supportDetailData.iPad.detail4.questionSolution.s1')}</P>`,
                `<P>${t('supportDetailData.iPad.detail4.questionSolution.s2')}</P>`,
              ],
            },
            {
              questionDescribe: t('supportDetailData.iPad.detail4.questionDescribe.d2'),
              questionSolution: [
                `<h4>${t('supportDetailData.iPad.detail4.questionSolution.s3')}</h4>`,
                `<P>${t('supportDetailData.iPad.detail4.questionSolution.s4')}</P>`,
                `<P>${t('supportDetailData.iPad.detail4.questionSolution.s5')}</P>`,
                `<P>${t('supportDetailData.iPad.detail4.questionSolution.s6')}</P>`,
                `<P>${t('supportDetailData.iPad.detail4.questionSolution.s7')}</P>`,
                `<P>${t('supportDetailData.iPad.detail4.questionSolution.s8')}</P>`,
                `<P>${t('supportDetailData.iPad.detail4.questionSolution.s9')}</P>`,
              ],
            },
          ],
        },
        {
          questionType: t('supportDetailData.iPad.detail5.questionType'),
          img: '/images/support-detail/repairs.png',
          questionList: [
            {
              questionDescribe: t('supportDetailData.iPad.detail5.questionDescribe'),
              questionSolution: [
                `<P>${t('supportDetailData.iPad.detail5.questionSolution.s1')}<a href="/contact">${t('supportDetailData.iPad.detail5.questionSolution.s2')}</a>${t('supportDetailData.iPad.detail5.questionSolution.s3')}</P>`,
              ],
            },
          ],
        },
        {
          questionType: t('supportDetailData.iPad.detail6.questionType'),
          img: '/images/support-detail/system.png',
          questionList: [
            {
              questionDescribe: t('supportDetailData.iPad.detail6.questionDescribe.d1'),
              questionSolution: [
                `<P>${t('supportDetailData.iPad.detail6.questionSolution.s1')}</P>`,
                `<h4>${t('supportDetailData.iPad.detail6.questionSolution.s2')}</h4>`,
                `<P>${t('supportDetailData.iPad.detail6.questionSolution.s3')}</P>`,
                `<P>${t('supportDetailData.iPad.detail6.questionSolution.s4')}</P>`,
                `<P>${t('supportDetailData.iPad.detail6.questionSolution.s5')}</P>`,
                `<P>${t('supportDetailData.iPad.detail6.questionSolution.s6')}</P>`,
                `<P>${t('supportDetailData.iPad.detail6.questionSolution.s7')}</P>`,
                `<P>${t('supportDetailData.iPad.detail6.questionSolution.s8')}</P>`,
              ],
            },
            {
              questionDescribe: t('supportDetailData.iPad.detail6.questionDescribe.d2'),

              questionSolution: [
                `<h4>${t('supportDetailData.iPad.detail6.questionSolution.s9')}</h4>`,
                t('supportDetailData.iPad.detail6.questionSolution.s10'),
                t('supportDetailData.iPad.detail6.questionSolution.s11'),
                t('supportDetailData.iPad.detail6.questionSolution.s12'),
                t('supportDetailData.iPad.detail6.questionSolution.s13'),
                t('supportDetailData.iPad.detail6.questionSolution.s14'),
                t('supportDetailData.iPad.detail6.questionSolution.s15'),
                t('supportDetailData.iPad.detail6.questionSolution.s16'),
                t('supportDetailData.iPad.detail6.questionSolution.s17'),
                t('supportDetailData.iPad.detail6.questionSolution.s18'),
                `<h4>${t('supportDetailData.iPad.detail6.questionSolution.s19')}</h4>`,
                t('supportDetailData.iPad.detail6.questionSolution.s20'),
                t('supportDetailData.iPad.detail6.questionSolution.s21'),
                t('supportDetailData.iPad.detail6.questionSolution.s22'),
                t('supportDetailData.iPad.detail6.questionSolution.s23'),
                t('supportDetailData.iPad.detail6.questionSolution.s24'),
                t('supportDetailData.iPad.detail6.questionSolution.s25'),
                t('supportDetailData.iPad.detail6.questionSolution.s26'),
                t('supportDetailData.iPad.detail6.questionSolution.s27'),
                t('supportDetailData.iPad.detail6.questionSolution.s28'),
              ],
            },
            {
              questionDescribe: t('supportDetailData.iPad.detail6.questionDescribe.d3'),
              questionSolution: [
                `<P>${t('supportDetailData.iPad.detail6.questionSolution.s29')}</P>`,
                `<P>${t('supportDetailData.iPad.detail6.questionSolution.s30')}</P>`,
                t('supportDetailData.iPad.detail6.questionSolution.s31'),
                t('supportDetailData.iPad.detail6.questionSolution.s32'),
                t('supportDetailData.iPad.detail6.questionSolution.s33'),
                t('supportDetailData.iPad.detail6.questionSolution.s34'),
                t('supportDetailData.iPad.detail6.questionSolution.s35'),
              ],
            },
            {

              questionDescribe: t('supportDetailData.iPad.detail6.questionDescribe.d4'),
              questionSolution: [

                t('supportDetailData.iPad.detail6.questionSolution.s36'),

                `<span>${t('supportDetailData.iPad.detail6.questionSolution.s37')}</span>${t('supportDetailData.iPad.detail6.questionSolution.s41')}`,
                `<span>${t('supportDetailData.iPad.detail6.questionSolution.s38')}</span>${t('supportDetailData.iPad.detail6.questionSolution.s42')}`,
                `<span>${t('supportDetailData.iPad.detail6.questionSolution.s39')}</span> ${t('supportDetailData.iPad.detail6.questionSolution.s43')}`,
                `<span>${t('supportDetailData.iPad.detail6.questionSolution.s40')}</span> ${t('supportDetailData.iPad.detail6.questionSolution.s44')}`,

              ],
            },
          ],
        },
      ],
    },
    {
      type: 'iPhone',
      detail: [
        {
          questionType: t('supportDetailData.iPhone.detail1.questionType'),
          img: '/images/support-detail/audio.png',
          questionList: [
            {
              questionDescribe: t('supportDetailData.iPhone.detail1.questionDescribe.d1'),
              questionSolution: [
                t('supportDetailData.iPhone.detail1.questionSolution.s1'),
                t('supportDetailData.iPhone.detail1.questionSolution.s2'),
                t('supportDetailData.iPhone.detail1.questionSolution.s3'),
                t('supportDetailData.iPhone.detail1.questionSolution.s4'),
                t('supportDetailData.iPhone.detail1.questionSolution.s5'),

              ],
            },
            {
              questionDescribe: t('supportDetailData.iPhone.detail1.questionDescribe.d2'),
              questionSolution: [
                t('supportDetailData.iPhone.detail1.questionSolution.s6'),
                t('supportDetailData.iPhone.detail1.questionSolution.s7'),

              ],
            },
          ],
        },
        {
          questionType: t('supportDetailData.iPhone.detail2.questionType'),
          img: '/images/support-detail/battery.png',
          questionList: [
            {
              questionDescribe: t('supportDetailData.iPhone.detail2.questionDescribe.d1'),
              questionSolution: [
                `${t('supportDetailData.iPhone.detail2.questionSolution.s2')}<a href="/returns">${t('supportDetailData.iPhone.detail2.questionSolution.s3')}</a>.`,
              ],
            },
            {
              questionDescribe: t('supportDetailData.iPhone.detail2.questionDescribe.d2'),
              questionSolution: [
                t('supportDetailData.iPhone.detail2.questionSolution.s1'),
                t('supportDetailData.iPhone.detail2.questionSolution.s4'),
                `<h4>${t('supportDetailData.iPhone.detail2.questionSolution.s5')},</h4>`,
              ],
            },
            {
              questionDescribe: t('supportDetailData.iPhone.detail2.questionDescribe.d3'),
              questionSolution: [
                t('supportDetailData.iPhone.detail2.questionSolution.s6'),
                t('supportDetailData.iPhone.detail2.questionSolution.s7'),
                `${t('supportDetailData.iPhone.detail2.questionSolution.s8')},<a href="/">${t('supportDetailData.iPhone.detail2.questionSolution.s9')}</a>`,
              ],
            },
          ],
        },
        {
          questionType: t('supportDetailData.iPhone.detail3.questionType'),
          img: '/images/support-detail/condition.png',
          questionList: [
            {
              questionDescribe: t('supportDetailData.iPhone.detail3.questionDescribe.d1'),
              questionSolution: [
                `<P>${t('supportDetailData.iPhone.detail3.questionSolution.s1')}</P>`,
                `<P>${t('supportDetailData.iPhone.detail3.questionSolution.s2')}</P><P>${t('supportDetailData.iPhone.detail3.questionSolution.s8')}</P>`,
                `<P>${t('supportDetailData.iPhone.detail3.questionSolution.s3')}</P><P>${t('supportDetailData.iPhone.detail3.questionSolution.s9')}</P>`,
                `<P>${t('supportDetailData.iPhone.detail3.questionSolution.s4')}</P><P>${t('supportDetailData.iPhone.detail3.questionSolution.s10')}</P>`,
              ],
            },
            {
              questionDescribe: t('supportDetailData.iPhone.detail3.questionDescribe.d2'),
              questionSolution: [
                `<P>${t('supportDetailData.iPhone.detail3.questionSolution.s5')}</P>`,

              ],
            },
            {
              questionDescribe: t('supportDetailData.iPhone.detail3.questionDescribe.d3'),
              questionSolution: [
                `<P>${t('supportDetailData.iPhone.detail3.questionSolution.s6')}</P>`,
              ],
            },
            {
              questionDescribe: t('supportDetailData.iPhone.detail3.questionDescribe.d4'),
              questionSolution: [
                `<P>${t('supportDetailData.iPhone.detail3.questionSolution.s7')}</P>`,
              ],
            },
          ],
        },
        {
          questionType: t('supportDetailData.iPhone.detail4.questionType'),
          img: '/images/support-detail/connectivity.png',
          questionList: [
            {
              questionDescribe: t('supportDetailData.iPhone.detail4.questionDescribe.d1'),
              questionSolution: [
                `<h4${t('supportDetailData.iPhone.detail4.questionSolution.s1')}</h4>`,
                t('supportDetailData.iPhone.detail4.questionSolution.s2'),
                t('supportDetailData.iPhone.detail4.questionSolution.s3'),
                t('supportDetailData.iPhone.detail4.questionSolution.s4'),
                t('supportDetailData.iPhone.detail4.questionSolution.s5'),
                t('supportDetailData.iPhone.detail4.questionSolution.s6'),
              ],
            },
          ],
        },
        {
          questionType: t('supportDetailData.iPhone.detail5.questionType'),
          img: '/images/support-detail/repairs.png',
          questionList: [
            {
              questionDescribe: t('supportDetailData.iPhone.detail5.questionDescribe'),
              questionSolution: [
                `<P>${t('supportDetailData.iPhone.detail5.questionSolution.s1')}<a href="/contact">${t('supportDetailData.iPhone.detail5.questionSolution.s2')}</a>${t('supportDetailData.iPhone.detail5.questionSolution.s3')}</P>`,
              ],
            },
          ],
        },
        {
          questionType: t('supportDetailData.iPhone.detail6.questionType'),
          img: '/images/support-detail/system.png',
          questionList: [
            {
              questionDescribe: t('supportDetailData.iPhone.detail6.questionDescribe.d1'),
              questionSolution: [
                `<P>${t('supportDetailData.iPhone.detail6.questionSolution.s1')}</P>`,
                `<h4>${t('supportDetailData.iPhone.detail6.questionSolution.s2')}</h4>`,
                `<P>${t('supportDetailData.iPhone.detail6.questionSolution.s3')}</P>`,
                `<P>${t('supportDetailData.iPhone.detail6.questionSolution.s4')}</P>`,
                `<P>${t('supportDetailData.iPhone.detail6.questionSolution.s5')}</P>`,
                `<P>${t('supportDetailData.iPhone.detail6.questionSolution.s6')}</P>`,
                `<P>${t('supportDetailData.iPhone.detail6.questionSolution.s7')}</P>`,
                `<P>${t('supportDetailData.iPhone.detail6.questionSolution.s8')}</P>`,
              ],
            },
            {
              questionDescribe: t('supportDetailData.iPhone.detail6.questionDescribe.d2'),

              questionSolution: [
                `<h4>${t('supportDetailData.iPhone.detail6.questionSolution.s9')}</h4>`,
                t('supportDetailData.iPhone.detail6.questionSolution.s10'),
                t('supportDetailData.iPhone.detail6.questionSolution.s11'),
                t('supportDetailData.iPhone.detail6.questionSolution.s12'),
                t('supportDetailData.iPhone.detail6.questionSolution.s13'),
                t('supportDetailData.iPhone.detail6.questionSolution.s14'),
                t('supportDetailData.iPhone.detail6.questionSolution.s15'),
                t('supportDetailData.iPhone.detail6.questionSolution.s16'),
                t('supportDetailData.iPhone.detail6.questionSolution.s17'),
                t('supportDetailData.iPhone.detail6.questionSolution.s18'),
                `<h4>${t('supportDetailData.iPhone.detail6.questionSolution.s19')}</h4>`,
                t('supportDetailData.iPhone.detail6.questionSolution.s20'),
                t('supportDetailData.iPhone.detail6.questionSolution.s21'),
                t('supportDetailData.iPhone.detail6.questionSolution.s22'),
                t('supportDetailData.iPhone.detail6.questionSolution.s23'),
                t('supportDetailData.iPhone.detail6.questionSolution.s24'),
                t('supportDetailData.iPhone.detail6.questionSolution.s25'),
                t('supportDetailData.iPhone.detail6.questionSolution.s26'),
                t('supportDetailData.iPhone.detail6.questionSolution.s27'),
                t('supportDetailData.iPhone.detail6.questionSolution.s28'),
              ],
            },
            {
              questionDescribe: t('supportDetailData.iPhone.detail6.questionDescribe.d3'),
              questionSolution: [
                `<P>${t('supportDetailData.iPhone.detail6.questionSolution.s29')}</P>`,
                `<P>${t('supportDetailData.iPhone.detail6.questionSolution.s30')}</P>`,
                t('supportDetailData.iPhone.detail6.questionSolution.s31'),
                t('supportDetailData.iPhone.detail6.questionSolution.s32'),
                t('supportDetailData.iPhone.detail6.questionSolution.s33'),
                t('supportDetailData.iPhone.detail6.questionSolution.s34'),
                t('supportDetailData.iPhone.detail6.questionSolution.s35'),
              ],
            },
            {

              questionDescribe: t('supportDetailData.iPhone.detail6.questionDescribe.d4'),
              questionSolution: [

                t('supportDetailData.iPhone.detail6.questionSolution.s36'),

                `<span>${t('supportDetailData.iPhone.detail6.questionSolution.s37')}</span>${t('supportDetailData.iPhone.detail6.questionSolution.s41')}`,
                `<span>${t('supportDetailData.iPhone.detail6.questionSolution.s38')}</span>${t('supportDetailData.iPhone.detail6.questionSolution.s42')}`,
                `<span>${t('supportDetailData.iPhone.detail6.questionSolution.s39')}</span> ${t('supportDetailData.iPhone.detail6.questionSolution.s43')}`,
                `<span>${t('supportDetailData.iPhone.detail6.questionSolution.s40')}</span> ${t('supportDetailData.iPhone.detail6.questionSolution.s44')}`,

              ],
            },
          ],
        },
      ],
    },
    {
      type: 'Watch',
      detail: [
        {
          questionType: t('supportDetailData.watch.detail1.questionType'),
          img: '/images/support-detail/battery.png',
          questionList: [
            {
              questionDescribe: t('supportDetailData.watch.detail1.questionDescribe.d1'),
              questionSolution: [
                `<h4>${t('supportDetailData.watch.detail1.questionSolution.s1')}</h4>`,
                t('supportDetailData.watch.detail1.questionSolution.s2'),
                t('supportDetailData.watch.detail1.questionSolution.s3'),
                t('supportDetailData.watch.detail1.questionSolution.s4'),
                t('supportDetailData.watch.detail1.questionSolution.s5'),
                t('supportDetailData.watch.detail1.questionSolution.s6'),
                `${t('supportDetailData.watch.detail1.questionSolution.s7')}<a href="https://support.apple.com/en-gb/HT204640">https://support.apple.com/en-gb/HT204640</a>.`,
              ],
            },
            {
              questionDescribe: t('supportDetailData.watch.detail1.questionDescribe.d2'),
              questionSolution: [
                t('supportDetailData.watch.detail1.questionSolution.s8'),
                `${t('supportDetailData.watch.detail1.questionSolution.s9')}<a href="https://support.apple.com/en-gb/HT204640">https://support.apple.com/en-gb/HT204640</a>.`,
              ],
            },
          ],
        },
        {
          questionType: t('supportDetailData.watch.detail2.questionType'),

          img: '/images/support-detail/connectivity.png',
          questionList: [
            {
              questionDescribe: t('supportDetailData.watch.detail2.questionDescribe.d1'),

              questionSolution: [
                t('supportDetailData.watch.detail2.questionSolution.s1'),
              ],
            },
          ],
        },
        {
          questionType: t('supportDetailData.watch.detail3.questionType'),

          img: '/images/support-detail/condition.png',
          questionList: [
            {
              questionDescribe: t('supportDetailData.watch.detail3.questionDescribe.d1'),
              questionSolution: [
                `<P>${t('supportDetailData.watch.detail3.questionSolution.s1')}</P>`,
                `<P>${t('supportDetailData.watch.detail3.questionSolution.s2')}</P><P>${t('supportDetailData.watch.detail3.questionSolution.s8')}</P>`,
                `<P>${t('supportDetailData.watch.detail3.questionSolution.s3')}</P><P>${t('supportDetailData.watch.detail3.questionSolution.s9')}</P>`,
                `<P>${t('supportDetailData.watch.detail3.questionSolution.s4')}</P><P>${t('supportDetailData.watch.detail3.questionSolution.s10')}</P>`,
              ],
            },
            {
              questionDescribe: t('supportDetailData.watch.detail3.questionDescribe.d2'),
              questionSolution: [
                `<P>${t('supportDetailData.watch.detail3.questionSolution.s5')}</P>`,

              ],
            },
            {
              questionDescribe: t('supportDetailData.watch.detail3.questionDescribe.d3'),
              questionSolution: [
                `<P>${t('supportDetailData.watch.detail3.questionSolution.s6')}</P>`,
              ],
            },
            {
              questionDescribe: t('supportDetailData.watch.detail3.questionDescribe.d4'),
              questionSolution: [
                `<P>${t('supportDetailData.watch.detail3.questionSolution.s7')}</P>`,
              ],
            },
          ],
        },
        {
          questionType: t('supportDetailData.watch.detail4.questionType'),

          img: '/images/support-detail/connectivity.png',
          questionList: [
            {
              questionDescribe: t('supportDetailData.watch.detail4.questionDescribe.d1'),
              questionSolution: [
                t('supportDetailData.watch.detail4.questionSolution.s1'),
                t('supportDetailData.watch.detail4.questionSolution.s2'),

                `<h4>${t('supportDetailData.watch.detail4.questionSolution.s3')}</h4>`,
                t('supportDetailData.watch.detail4.questionSolution.s4'),
                t('supportDetailData.watch.detail4.questionSolution.s5'),
                t('supportDetailData.watch.detail4.questionSolution.s6'),
                t('supportDetailData.watch.detail4.questionSolution.s7'),
                `<h4>${t('supportDetailData.watch.detail4.questionSolution.s8')}</h4>`,
                t('supportDetailData.watch.detail4.questionSolution.s9'),
                t('supportDetailData.watch.detail4.questionSolution.s10'),
                t('supportDetailData.watch.detail4.questionSolution.s11'),
                `${t('supportDetailData.watch.detail4.questionSolution.s12')}<a href="https://support.apple.com/en-gb/HT205025">https://support.apple.com/en-gb/HT205025</a>`,
              ],
            },
          ],
        },
        {
          questionType: t('supportDetailData.watch.detail5.questionType'),

          img: '/images/support-detail/repairs.png',
          questionList: [
            {
              questionDescribe: t('supportDetailData.watch.detail4.questionDescribe.d1'),
              questionSolution: [
                `${t('supportDetailData.watch.detail5.questionSolution.s1')}<a href="/contact">${t('supportDetailData.watch.detail5.questionSolution.s2')}</a>${t('supportDetailData.watch.detail5.questionSolution.s3')}`,
              ],
            },
          ],
        },
        {
          questionType: t('supportDetailData.watch.detail6.questionType'),

          img: '/images/support-detail/system.png',
          questionList: [
            {
              questionDescribe: t('supportDetailData.watch.detail6.questionDescribe.d1'),
              questionSolution: [
                `<P>${t('supportDetailData.watch.detail6.questionSolution.s1')}</P>`,
                `<h4>${t('supportDetailData.watch.detail6.questionSolution.s2')}</h4>`,
                `<P>${t('supportDetailData.watch.detail6.questionSolution.s3')}</P>`,
                `<P>${t('supportDetailData.watch.detail6.questionSolution.s4')}</P>`,
                `<P>${t('supportDetailData.watch.detail6.questionSolution.s5')}</P>`,
                `<P>${t('supportDetailData.watch.detail6.questionSolution.s6')}</P>`,
                `<P>${t('supportDetailData.watch.detail6.questionSolution.s7')}</P>`,
                `<P>${t('supportDetailData.watch.detail6.questionSolution.s8')}</P>`,
              ],
            },
            {
              questionDescribe: t('supportDetailData.watch.detail6.questionDescribe.d2'),
              questionSolution: [
                `<h4>${t('supportDetailData.watch.detail6.questionSolution.s9')}</h4>`,
                t('supportDetailData.watch.detail6.questionSolution.s10'),
                t('supportDetailData.watch.detail6.questionSolution.s11'),
                t('supportDetailData.watch.detail6.questionSolution.s12'),
                t('supportDetailData.watch.detail6.questionSolution.s13'),
                `<h4>${t('supportDetailData.watch.detail6.questionSolution.s14')}</h4>`,
                t('supportDetailData.watch.detail6.questionSolution.s15'),
                t('supportDetailData.watch.detail6.questionSolution.s16'),
                t('supportDetailData.watch.detail6.questionSolution.s17'),
                t('supportDetailData.watch.detail6.questionSolution.s18'),
                t('supportDetailData.watch.detail6.questionSolution.s19'),
                t('supportDetailData.watch.detail6.questionSolution.s20'),
              ],
            },
            {
              questionDescribe: t('supportDetailData.watch.detail6.questionDescribe.d3'),
              questionSolution: [
                t('supportDetailData.watch.detail6.questionSolution.s21'),
                '*Series 1',
                '*Series 2',
                '*Series 3',
                '*Series 4',
                '*Series 5',
                t('supportDetailData.watch.detail6.questionSolution.s22'),
                '*Series 1',
                '*Series 2',
                '*Series 3',
                '*Series 4',
                '*Series 5',
                `${t('supportDetailData.watch.detail6.questionSolution.s23')}<a href="https://support.apple.com/en-gb/HT204641">https://support.apple.com/en-gb/HT204641</a>`,
              ],
            },
          ],
        },
      ],
    },
  ];
}
