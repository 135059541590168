
import importComponents from '@/utils/import-components';
import {
  computed, defineComponent, reactive, ref,
} from 'vue';
import ItemHeader from '@/components/footerlink/ItemHeader.vue';
import ItemWrapper from '@/components/footerlink/ItemWrapper.vue';
import { useRoute } from 'vue-router';
import supportDetail from '@/views/footerlink/support/support-detail';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'SupportDetail',
  components: {
    ItemHeader,
    ItemWrapper,
    ...importComponents(
    ),
  },
  setup() {
    const route = useRoute();
    const { t } = useI18n();
    const { type } = route.params;
    const goodsType = type;
    const supportData = reactive({
      ...supportDetail(t).filter((item) => item.type === type)[0],
    });
    const detailIndex = ref(0);
    const btnIndex = ref(0);
    const questionList = computed(() => supportData.detail[detailIndex.value]
      .questionList);
    const questionSolution = computed(() => supportData.detail[detailIndex.value]
      .questionList[btnIndex.value].questionSolution);
    const typeActive = (index: number) => {
      btnIndex.value = 0;
      detailIndex.value = index;
    };
    const btnActive = (index: number) => {
      btnIndex.value = index;
    };

    return {
      goodsType,
      supportData,
      detailIndex,
      typeActive,
      btnActive,
      btnIndex,
      questionSolution,
      questionList,
      t,
    };
  },
});
